import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import contactTop from "../../img/contactTop.png";
import {
  ContactCalendar,
  ContactLocation,
  ContactMail,
  ContactPhone,
} from "../../img/svgs";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [statusMessage, setStatusMessage] = useState(""); // Add state for status message
  const [statusType, setStatusType] = useState(""); // Add state for status type (success or error)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, phone, email, message } = formData;
    const botToken = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
    const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;
    const text = `
🎉 New Tour Inquiry 🎉

👤 Name: ${name}
📞 Phone: ${phone}
✉️ Email: ${email}

📝 Message:
${message}

🚀 We're excited to help plan your perfect tour!
    `;

    const url = `https://api.telegram.org/bot${botToken}/sendMessage`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: text,
        }),
      });

      if (response.ok) {
        setStatusType("success"); // Set success type
        setStatusMessage("Message sent successfully!"); // Set success message
        setFormData({ name: "", phone: "", email: "", message: "" }); // Clear form data
      } else {
        setStatusType("error"); // Set error type
        setStatusMessage("Failed to send message."); // Set error message
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setStatusType("error"); // Set error type
      setStatusMessage("Error sending message."); // Set error message
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-col justify-between">
      <Header />
      <main className="mt-20 flex flex-col gap-20">
        <div
          style={{ backgroundImage: `url(${contactTop})` }}
          className="flex h-[350px] w-full bg-cover bg-center bg-no-repeat"
        ></div>
        <div className="container mx-auto flex flex-col justify-between gap-5 px-5 lg:flex-row">
          {/* Left section for contact details */}
          <div className="flex flex-1 flex-col gap-8">
            <div className="flex items-center gap-4 rounded-[10px] border border-[#eeeeee] p-5">
              <div className="flex h-11 w-11 items-center justify-center rounded-[50px] bg-[#63ab45] p-3">
                <ContactPhone />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold leading-snug text-[#100c08]">
                  +998(88)111-85-32
                </span>
                <span className="text-base font-bold leading-snug text-[#100c08]">
                  +998(88)111-85-32
                </span>
              </div>
            </div>
            <div className="flex items-center gap-4 rounded-[10px] border border-[#eeeeee] p-5">
              <div className="flex h-11 w-11 items-center justify-center rounded-[50px] bg-[#63ab45] p-3">
                <ContactMail />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold leading-snug text-[#100c08]">
                  <a href="mailto:ergashevadilnura632@gmail.com">
                    ergashevadilnura632@gmail.com
                  </a>
                </span>
              </div>
            </div>
            <div className="flex items-center gap-4 rounded-[10px] border border-[#eeeeee] p-5">
              <div className="flex h-11 w-11 items-center justify-center rounded-[50px] bg-[#63ab45] p-3">
                <ContactCalendar />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-base font-bold leading-snug text-[#100c08]">
                  24/7
                </span>
              </div>
            </div>
          </div>

          {/* Right section for form */}
          <form
            onSubmit={handleSubmit}
            className="flex flex-1 flex-col items-start rounded-[10px] bg-amber-50 p-8 lg:p-16"
          >
            <span className="text-3xl font-bold leading-[42px] text-[#100c08]">
              Reach Us Anytime
            </span>

            {statusMessage && (
              <div
                className={`mt-4 rounded p-4 text-white ${
                  statusType === "success" ? "bg-green-500" : "bg-red-500"
                }`}
              >
                {statusMessage}
              </div>
            )}

            <div className="mt-5 flex w-full flex-col">
              <span className="text-[13px] font-medium leading-[13px] text-[#5d5b58]">
                Name*
              </span>
              <input
                className="mt-2 px-5 py-4 outline-[#63ab45]"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                required
              />
            </div>
            <div className="flex w-full flex-col gap-6 lg:flex-row">
              <div className="mt-5 flex flex-1 flex-col">
                <span className="text-[13px] font-medium leading-[13px] text-[#5d5b58]">
                  Phone*
                </span>
                <input
                  className="mt-2 px-5 py-4 outline-[#63ab45]"
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone Number"
                  required
                />
              </div>
              <div className="mt-5 flex flex-1 flex-col">
                <span className="text-[13px] font-medium leading-[13px] text-[#5d5b58]">
                  Email*
                </span>
                <input
                  className="mt-2 px-5 py-4 outline-[#63ab45]"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Us..."
                  required
                />
              </div>
            </div>
            <div className="mt-5 flex w-full flex-1 flex-col">
              <span className="text-[13px] font-medium leading-[13px] text-[#5d5b58]">
                Write Your Message*{" "}
              </span>
              <textarea
                className="mt-2 w-full px-5 py-4 outline-[#63ab45]"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Message..."
                required
              />
            </div>
            <button
              type="submit"
              className="mt-8 flex w-full items-center justify-center rounded-[5px] bg-[#63ab45] px-6 py-4 text-base font-semibold capitalize leading-none tracking-wide text-white lg:w-auto"
            >
              Submit Now
            </button>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
